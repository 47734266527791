<template>
  <component
    :is="component"
    :type="buttonType"
    :to="to"
    :class="[...variantStyle(), ...sizeStyle()]"
    :disabled="isDisabled || isLoading"
    class="justify-center inline-block text-sm border rounded-full focus:outline-none disabled:text-gray-500 disabled:pointer-events-none"
  >
    <VLoadingSpinner v-if="isLoading" />
    <slot v-else />
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { Size, Variant } from '@/types/enums'
import VLoadingSpinner from './VLoadingSpinner.vue'

const props = defineProps({
  to: {
    type: String,
    default: null,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String as PropType<Size>,
    default: Size.md,
  },
  variant: {
    type: String as PropType<Variant>,
    default: Variant.primary,
  },
  type: {
    type: String as PropType<'button' | 'submit' | 'reset'>,
    default: 'button',
  },
})

const component = computed(() => {
  if (props.to) return resolveComponent('NuxtLink')
  return 'button'
})

const buttonType = computed(() => {
  return component.value === 'button' ? props.type : undefined
})

function variantStyle() {
  switch (props.variant) {
    case Variant.primary:
      return [
        'text-white',
        'bg-cred',
        'font-medium',
        'border-transparent',
        'hover:bg-cred-100',
        'focus:ring-2',
        'focus:ring-offset-2',
        'focus:ring-cblack',
        'disabled:bg-gray-200',
      ]
    case Variant.secondary:
      return [
        'text-cblack',
        'font-medium',
        'bg-transparent',
        'border-cred',
        'hover:bg-cblack',
        'hover:border-cblack',
        'hover:text-white',
        'disabled:bg-gray-200',
      ]
    case Variant.secondaryDark:
      return [
        'text-white',
        'bg-cblack',
        'font-medium',
        'hover:bg-white',
        'hover:text-cblack',
        'disabled:bg-gray-200',
      ]
    case Variant.tertiary:
      return [
        'text-cblack',
        'font-medium',
        'bg-transparent',
        'border-cblack',
        'hover:bg-cblack',
        'hover:border-cblack',
        'hover:text-white',
        'disabled:bg-gray-200',
      ]
    case Variant.success:
      return [
        'text-white',
        'font-medium',
        'bg-green-500',
        'border-transparent',
        'hover:bg-green-600',
        'focus:ring-2',
        'focus:ring-offset-2',
        'focus:ring-cblack',
        'disabled:bg-gray-200',
      ]
    case Variant.link:
      return [
        'text-cblack',
        'font-medium',
        'border-transparent',
        'hover:underline',
      ]
    case Variant.secondaryLink:
      return [
        'text-cred',
        'font-medium',
        'border-transparent',
        'hover:underline',
      ]
    case Variant.tertiaryLink:
      return [
        'text-cblack',
        'text-[16px]',
        'font-light',
        'border-transparent',
        'underline',
        'underline-offset-2',
      ]
    case Variant.quaternaryLink:
      return [
        'text-cpurple',
        'text-[14px]',
        'font-light',
        'border-transparent',
        'underline',
        'underline-offset-2',
      ]
  }
}

function sizeStyle() {
  switch (props.size) {
    case Size.xs:
      return ['p-1']
    case Size.sm:
      return ['p-2']
    case Size.md:
      return ['p-4']
    case Size.lg:
      return ['p-5']
  }
}
</script>
