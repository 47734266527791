export enum AuthCookie {
  auth = 'auth',
  refresh = 'refresh',
}

export enum DealRejectReason {
  noAvailability = 'NO_AVAILABILITY',
  priceTooLow = 'PRICE_TOO_LOW',
  deliveryLocationTooFar = 'DELIVERY_LOCATION_TOO_FAR',
  other = 'OTHER',
}

export enum DealStatus {
  pending = 'PENDING',
  accepted = 'ACCEPTED',
  rejected = 'REJECTED',
  moreInfo = 'SUPPLIER_REQUESTED_MORE_INFO',
  claimedByOthers = 'CLAIMED_BY_OTHERS',
}

export enum Download {
  handoverRequest = 'handoverRequest',
  contractDocument = 'contractDocument',
}

export enum FuelType {
  diesel = 'DIESEL',
  electric = 'ELECTRIC',
  gas = 'GAS',
  hydrogen = 'HYDROGEN',
  other = 'OTHER',
}

export enum InsuranceType {
  full = 'FULL',
  partial = 'PARTIAL',
  none = 'NONE',
}

export enum InvoicePeriodType {
  days28 = '28_DAYS',
  days30 = '30_DAYS',
  calendarMonth = 'CALENDAR_MONTH',
}

export enum RentalDeposit {
  NONE = 'NONE',
  ONE_MONTH = 'ONE_MONTH',
  TWO_MONTHS = 'TWO_MONTHS',
  BY_CREDIT_SCORE = 'BY_CREDIT_SCORE',
}

export enum Size {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export enum StepStatus {
  current = 'current',
  upcoming = 'upcoming',
  completed = 'completed',
}

export enum TransmissionTypeOptions {
  automatic = 'AUTOMATIC',
  semiAutomatic = 'SEMIAUTOMATIC',
  manual = 'MANUAL',
}

export enum Variant {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  secondaryDark = 'secondaryDark',
  success = 'success',
  link = 'link',
  secondaryLink = 'secondaryLink',
  tertiaryLink = 'tertiaryLink',
  quaternaryLink = 'quaternaryLink',
}

export enum VehicleStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  RENTED = 'RENTED',
}

export enum VehicleCategory {
  trailer = 'trailer',
  truck = 'truck',
  lkw = 'lkw',
  body = 'body',
  transporter = 'transporter',
}

export enum TruckSubTypes {
  standard = 'standard',
  low_liner = 'low_liner',
  tilting_hydraulics = 'tilting_hydraulics',
}

export enum LkwSubTypes {
  tonner_3_5 = 'tonner_3_5',
  tonner_7_2 = 'tonner_7_2',
  tonner_7_5 = 'tonner_7_5',
  tonner_12 = 'tonner_12',
  tonner_16 = 'tonner_16',
  bdf = 'bdf',
}

export enum TrailerSubTypes {
  curtainsider = 'curtainsider',
  box_trailer = 'box_trailer',
  container_chassis = 'container_chassis',
  mega_curtainsider = 'mega_curtainsider',
  tipper_trailer = 'tipper_trailer',
  reefer = 'reefer',
  moving_floor = 'moving_floor',
}

export enum BodySubTypes {
  curtainsider = 'curtainsider',
  box_trailer = 'box_trailer',
  thermo = 'thermo',
}

export enum TransporterSubTypes {
  van = 'van',
}

export enum VehicleAttributesMultiSelects {
  axis_number = 'axis_number',
  back_cover = 'back_cover',
  color = 'color',
  condition = 'condition',
  construction = 'construction',
  cooling = 'cooling',
  drive_type = 'drive_type',
  foot = 'foot',
  material = 'material',
  type_of_use = 'type_of_use',
  wall = 'wall',
}

export enum VehicleAttributesDates {
  last_main_inspection = 'last_main_inspection',
  last_security_inspection = 'last_security_inspection',
}

export enum RentalPeriod {
  day = 'day',
  week = 'week',
  month = 'month',
}

// TODO: Remove whne using new marketplace filters
export enum RequestsSortBy {
  created_at = 'created_at',
  rental_start_date = 'rental_start_date',
}

export enum Actions {
  new = 'new',
  update = 'update',
}

export enum OrderUrgency {
  information = 'information',
  concrete = 'concrete',
  urgent = 'urgent',
}

export enum OrderOfferStatus {
  viewed = 'viewed',
  answered = 'answered',
}

export enum OrderStatus {
  new = 'new',
  public = 'public',
  closed = 'closed',
}
